<template>
  <mt-popup
    v-model="mtVisible"
    position="bottom"
    class="pick-city w100"
    lockScroll="true"
    :closeOnClickModal="false"
  >
    <div class="clearfix linkage" @touchmove.prevent>
      <div class="linkage-title fix">
        <a class="fl" @click="close">取消</a>
        <a @click="submitHandle" class="fr red">确定</a>
      </div>
      <div class="linkage-con fix">
        <div class="province pickerWrapper">
          <mt-picker
            :slots="provinces"
            @change="onProvinceChange"
            value-key="name"
          ></mt-picker>
        </div>
        <div class="city pickerWrapper">
          <mt-picker
            :slots="citys"
            @change="onCityChange"
            value-key="name"
          ></mt-picker>
        </div>
        <div class="area pickerWrapper">
          <mt-picker
            :slots="areas"
            @change="onAreaChange"
            value-key="name"
          ></mt-picker>
        </div>
      </div>
    </div>
  </mt-popup>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    mtpickVisible: {
      type: Boolean,
      default: false
    },
    seleCitys: {
      type: Array,
    },
  },
  data() {
    return {
      maskFlag: false,
      province: {
        name: "重庆",
        id: "1040000"
      },
      city: {
        name: "重庆",
        id: "1040100"
      },
      area: {
        name: "九龙坡",
        id: "1043900"
      },
      mtVisible: false,
      // 最开始省市区那三个picker会初始化调用change事件，但是此时没有省市区数据，因此会报错，所以以这个标识符来控制当时第一次初始化时调用change事件时直接return
      flag: 0,
      select: [],
      provinces: [],
      citys: [],
      areas: [],
      reload: true
    };
  },
  watch: {
    mtpickVisible: function(newValue, oldValue) {
      this.mtVisible = newValue;
      if (!newValue) {
        return false;
      }
      this.getData();
    }
  },
  computed: {},
  mounted() {},
  methods: {
    ...mapActions(["getRegion"]),
        /*
    * 获取某个元素下标
    * arr: 传入的数组
    * obj: 需要获取下标的元素
    * */
    getArrayIndex(arr, id) {
      var i = arr.length;
      while (i--) {
        if (arr[i].id === id) {
            return i;
        }
      }
      return -1;
    },
    getData() {
      let seleCitys = this.seleCitys;
      // 一级省
      this.getRegion().then(res => {
        let provinceIndex = 0;
        if (seleCitys.length > 0) {
          provinceIndex = this.getArrayIndex(res.data, seleCitys[0].id);
        }

        this.provinces = [
          {
            flex: 1,
            values: res.data,
            className: "slot1",
            textAlign: "center",
            defaultIndex: provinceIndex,
          },
          {
            divider: true,
            content: "-",
            className: "right"
          }
        ];
        this.province = {
          id: res.data[provinceIndex].id,
          name: res.data[provinceIndex].name
        };
        // 二级城市
        this.getRegion({
          id: res.data[provinceIndex].id
        }).then(res2 => {
          let cityIndex = 0;
          if (seleCitys.length > 0) {
            cityIndex = this.getArrayIndex(res2.data, seleCitys[1].id);
          }
          this.citys = [
            {
              flex: 1,
              values: res2.data,
              className: "slot1",
              textAlign: "center",
              defaultIndex: cityIndex,
            },
            {
              divider: true,
              content: "-",
              className: "slot2"
            }
          ];
          this.city = {
            id: res2.data[cityIndex].id,
            name: res2.data[cityIndex].name
          };

          // 三级区域
          this.getRegion({
            id: res2.data[cityIndex].id
          }).then(res3 => {
            let areaIndex = 0;
            if (seleCitys.length > 0) {
              areaIndex = this.getArrayIndex(res3.data, seleCitys[2].id);
            }
            this.flag = 1;
            this.areas = [
              {
                flex: 1,
                values: res3.data,
                className: "slot1",
                textAlign: "center",
                defaultIndex: areaIndex,
              }
            ];
            this.area = {
              id: res3.data[areaIndex].id,
              name: res3.data[areaIndex].name
            };
          });
        });
      });
    },
    submitHandle() {
      this.$emit("close");
      this.$emit("getLinkage", [this.province, this.city, this.area]);
    },
    close() {
      this.provinces = [];
      this.citys = [];
      this.areas = [];
      this.$emit("close");
    },
    onProvinceChange(picker, values) {
      if (this.flag === 0) {
        return;
      }
      this.province = {
        id: values[0].id,
        name: values[0].name
      };
      // 二级城市
      this.getRegion({
        id: values[0].id
      }).then(res2 => {
        this.city = {
          id: res2.data[0].id,
          name: res2.data[0].name
        };
        this.citys = [
          {
            flex: 1,
            values: res2.data,
            className: "slot1",
            textAlign: "center",
            defaultIndex: 0
          },
          {
            divider: true,
            content: "-",
            className: "slot2"
          }
        ];

        // 三级区域
        this.getRegion({
          id: res2.data[0].id
        }).then(res3 => {
          this.area = {
            id: res3.data[0].id,
            name: res3.data[0].name
          };
          this.areas = [
            {
              flex: 1,
              values: res3.data,
              className: "slot1",
              textAlign: "center",
              defaultIndex: 0
            }
          ];
        });
      });
    },
    onCityChange(picker, values) {
      if (this.flag === 0) {
        return;
      }
      this.city = {
        id: values[0].id,
        name: values[0].name
      };
      // 三级区域
      this.getRegion({
        id: values[0].id
      }).then(res3 => {
        this.areas = [
          {
            flex: 1,
            values: res3.data,
            className: "slot1",
            textAlign: "center",
            defaultIndex: 0
          }
        ];
      });
    },
    onAreaChange(picker, values) {
      if (this.flag === 0) {
        return;
      }
      this.area = {
        id: values[0].id,
        name: values[0].name
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.linkage {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  left: 0;
  overflow: hidden;
  z-index: 9999999999999;
}
.linkage-title {
  background-color: #f0eff4;
  padding: 0 10px;
  line-height: 0.8rem;
  margin-bottom: 0.24rem;
}
.linkage-title > a {
  font-size: 0.3rem;
  color: #555555;
}
.linkage-title > a:first-child {
  padding-right: 2rem;
}
.linkage-title > a.red {
  color: #ff504d;
  padding-left: 2rem;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.pickerWrapper {
  width: 33.3%;
  float: left;
}
.picker-item {
  font-size: 1.5rem;
}
</style>
