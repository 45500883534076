<template>
  <div>
    <common-header :title="title"></common-header>
    <div class="input-box">
      <div class="warp">
        <div class="item">
          <div class="tit">收货人</div>
          <input type="text" placeholder="请输入收货人姓名" v-model="addressData.receiveName">
        </div>
        <div class="item">
          <div class="tit">联系电话</div>
          <input type="text" placeholder="请输入收货人手机号码" v-model="addressData.receivePhone">
        </div>
        <div class="item">
          <div class="tit">所在区域</div>
          <input type="text" placeholder="请输入收货人所在区域" readonly @click="swicthCity" v-model="addressData.regionName">
        </div>
        <div class="item">
          <div class="tit">详细地址</div>
          <input type="text" placeholder="请输入详细地址" v-model="addressData.address" >
        </div>
        <div class="item">
          <div class="tit">默认地址</div>
          <mt-switch v-model="addressData.defaultAddress" color="#000"></mt-switch>
        </div>
      </div>
      <div class="fgx"></div>
      <div class="warp">
        <div class="item">
          <div class="tit">店铺名称</div>
          <input type="text" placeholder="请输入店铺名称" v-model="addressData.remark">
        </div>
        <div class="item">
          <div class="tit">经营类型</div>
          <input type="text" placeholder="如中餐，火锅，酒店等" v-model="addressData.addressKind">
        </div>
        <div class="upload-img-box">
          <div class="upload-img">
            <div class="name">门头照片<span class="ms">(最多三张)</span></div>
            <div class="imgs-box" >
              <div class="upload-btn img" v-if="imgArray.length < 3">
                <input type="file" class="file-input" accept="image/*" @change="choicePicture($event)" multiple="multiple">
              </div>
              <div class="img-bg img" v-for="(item, index) in imgArray" :key="index" :style="`background-image: url('${requestImgUrl(item.url)}')`">
                <img class="op-img" :src="requestImgUrl(item.url)" alt=""  preview="0">
                <img  src="" alt="">
                <div class="upload-fail" @click="deleteImg(index)" :style="`background-image:url('${require('@/assets/images/img/upload-fail.png')}')`"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <!-- 改变地区 start -->
    <mt-pick
      @getLinkage="showChildMsg"
      :mtpickVisible="showLinkage"
      @close="closeHandle"
      :seleCitys="seleCitys"
    ></mt-pick>
    <!-- 改变地区 end -->

    <!-- 立即绑定 start -->
    <div class="bind-phone-btn jb-r-l" @click="saveAddress">保存</div>
    <!-- 立即绑定 end -->
    <!-- 立即绑定 start -->
    <!-- <div class="bind-phone-btn lk">删除地址</div> -->
    <!-- 立即绑定 end -->
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import MtPick from "@/components/modules/MtPick";
import { requestFileName, requestImgUrl } from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.requestFileName = (file, cb, isPrivate) => requestFileName(file, cb, isPrivate);
    return {
      showLinkage: false,
      imgArray: [],
      addressData: {
        remark: "",
        address: "",
        defaultAddress: false,
        receiveName: "",
        receivePhone: "",
        regionId: "",
        regionName: "",
        addressKind: "",
        imgJson: "",
      },
      // 已选的城市
      seleCitys: [],
      id: "",
      title: this.$route.query.id ? "修改地址" : '新增地址',
    }
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      this.id = id;
      this.getUserAddress({
        id: id,
      }).then((res)=> {
        this.addressData = res.data;
        this.imgArray = JSON.parse(res.data.imgJson);
      })
    }
  },
  methods: {
    deleteImg(index) {
      this.imgArray.splice(index, 1);
    },
    choicePicture(e) {
      if (e.target.files.length == 0) {
        return false;
      }
      if (e.target.files.length > 3) {
        this.$toast('最多上传三张图片');
        return false;
      }
      this.$Indicator.open('上传图片中');
      const files = e.target.files
      let imgArray = [];
      for (var i = 0; i < files.length; i++) {
        const file = e.target.files[i];
        const success = i;
        this.requestFileName(file,(res)=> {
          if (success == files.length - 1) {
            setInterval(()=>{
              this.$Indicator.close();
            },500)
          }
          const img = {};
          if (Array.isArray(res.data)) {
            img.name = res.data[0].fileName;
            img.url = res.data[0].fileUri;
          } else {
            img.name = res.data.fileName;
            img.url = res.data.fileUri;
          }
          this.imgArray.push(img);
          this.$previewRefresh();
        }, false);
      }
    },
    swicthCity(i) {
      this.showLinkage = true;
    },
    // 关闭城市弹窗
    closeHandle() {
      this.showLinkage = false;
    },
    // 城市接收子组件数据
    showChildMsg(v) {
      const regions = `${v[0].id}-${v[1].id}-${v[2].id}`;
      const name = `${v[0].name}-${v[1].name}-${v[2].name}`;
      this.addressData.regionName = name;
      this.addressData.regionId = regions;
      this.seleCitys = v;
    },
    // 保存地址
    saveAddress() {
      let addressData = this.addressData;
      addressData.imgJson = JSON.stringify(this.imgArray);
      if (addressData.remark == "") {
        this.$toast("请输入店铺名称");
        return false;
      } else if (addressData.addressKind == "") {
        this.$toast("请输入经营类型");
        return false;
      } else if (this.imgArray.length  == 0) {
        this.$toast("请至少上传一张门头图片");
        return false;
      } else if (addressData.address == "") {
        this.$toast("请输入收货人姓名");
        return false;
      } else if (addressData.receivePhone == "" || addressData.receivePhone.length != 11) {
        this.$toast("请填写正确的联系电话");
        return false;
      } else if (addressData.regionId == "") {
        this.$toast("请选择地区");
        return false;
      } else if (addressData.address == "") {
        this.$toast("请输入详细地址");
        return false;
      } else {
        if (this.id) {
          this.userPutAddress(addressData).then((res)=> {
            this.$toast("地址保存成功");
            setTimeout(()=> {
              this.$router.go(0);
            }, 1500)
          })
        } else {
          this.userAddress(addressData).then((res)=> {
            this.$toast("地址保存成功");
            setTimeout(()=> {
              this.$router.go(0);
            }, 1500)
          })
        }
      }
    },
    ...mapActions("guide",[
      "userAddress",
      "getUserAddress",
      "userPutAddress",
    ])
  },
  components: {
    MtPick
  },
}
</script>
<style lang='less' scoped>
  .upload-img{
    padding: .2rem 0 .4rem;
    .imgs-box{
      display: flex;
      margin-top: .4rem;
      .img-bg{
        background-size: cover;
        position: relative;
        .upload-fail{
          position: absolute;
          top: -.2rem;
          right: 0;
          height: .5rem;
          width: .5rem;
          z-index: 3;
          background-size: .37rem .37rem;
          background-position: top right;
          background-repeat: no-repeat;
        }
        .op-img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .upload-btn{
        border: 1px solid #f5f5f5;
        background-image: url('../../../assets/images/img/upload-img.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50% 50%;
        position: relative;
        .file-input{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .img{
        width: 1.8rem;
        height: 1.8rem;
        margin-right: .15rem;
      }
    }
    .name{
      font-size: .32rem;
      position: relative;
      padding-left: .2rem;
        &::after{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
        left: 0;
        content: '*';
        color: red;
      }
      .ms{
        font-size: .26rem;
        color: #ccc;
      }
    }
  }
  .fgx{
    height: .15rem;
    background: #f5f5f5;
  }
.bind-phone-btn{
  width: 4.8rem;
  height: .85rem;
  border-radius: .4rem;
  color: #ffffff;
  font-size: .34rem;
  text-align: center;
  line-height: .85rem;
  margin: 1rem auto 0;
  &.lk{
    margin-top: .28rem;
  }
}
.item{
  display: flex;
  height: .9rem;
  align-items: center;
  position: relative;
  justify-content: space-between;
  .tit{
    width: 2rem;
    position: relative;
    padding-left: .2rem;
    &::after{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
      left: 0;
      content: '*';
      color: red;
    }
  }
  input{
    flex: 1;
    border-bottom: 1px solid #f5f5f5;
    height: 100%;
    font-size: .3rem;
  }
}
</style>